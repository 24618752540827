// MessageSection.jsx
import React from 'react';
import '../styles/ShortMessage.scss';

const MessageSection = () => {
  return (
    <section className="message-section">
      <div className="container">
        {/* <center className="header-container">
          <h4 className="section-title">Leadership Speaks</h4>
          <p className="section-subtitle">Guiding the path of excellence in education</p>
        </center> */}

        <div className="message-cards">
          {/* Director Card */}
          <div className="message-card director-card">
            <div className="image-container">
              <div className="overlay"></div>
              <img
                src="/images/director.png"
                alt="Director Shubh Nath Singh"
                className="profile-image"
              />
            </div>
            <div className="content">
              <div className="profile-info">
                <h4>Mr. Shubh Nath Singh</h4>
                <div className="designation">Director</div>
              </div>
              <p className="preview-text">
                "As we nurture young minds, our vision extends beyond academic excellence.
                We are committed to shaping...
              </p>
              <button className="read-more-btn">
                <a href="/director-message">Read Message</a>
              </button>

            </div>
          </div>

          {/* Principal Card */}
          <div className="message-card principal-card">
            <div className="image-container">
              <div className="overlay"></div>
              <img
                src="/images/principal.png"
                alt="Principal Bholu Singh Rathor"
                className="profile-image"
              />
            </div>
            <div className="content">
              <div className="profile-info">
                <h4>Mr. Kishan</h4>
                <div className="designation">Principal</div>
              </div>
              <p className="preview-text">
                "Our educational approach combines traditional values with modern innovation.
                We foster an environment where...
              </p>
              <button className="read-more-btn">
                <a href="/principal-message">Read Message</a>
              </button>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MessageSection;