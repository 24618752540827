import React from 'react'
import { useTranslation } from 'react-i18next'
import MessageSection from './ShortMessage';
import '../styles/Hero.scss'
import ContactUs from './ContactUs';
import Testimonials from './Testimonial';
import AboutSchool from './About';

export default function Hero() {
  const { t } = useTranslation();

  return (
    <div className='hero'>


      <MessageSection/>

      {/* <section className='video_section'>
        <video src="/ptvn_hero.mp4" autoPlay loop muted></video>
      </section> */}




      <section className='playSection'>
        <img src="/images/playclass.jpeg" alt="" />
        <div><center> ✨ PRABHAT TARA PLAY SCHOOL</center>
          <h2>  {t('play_section')}</h2></div>
      </section>
  

      <section className='firstSection'>
        <h2>{t('hero_text')}</h2>
        <div>
          <img src="/images/hero.png" alt="prabhat tara vidya niketan" />
        </div>
      </section>



      <section className='playSection'>
        <img src="/images/computer_lab.png" alt="" />
        <div>
          <h2>Building future-ready students with essential computer skills!</h2></div>
      </section>
      <AboutSchool/>
      <Testimonials/>


      <ContactUs/>

    </div>
  )
}
