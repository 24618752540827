import React from 'react';
import '../styles/TeachingStaff.scss'
import { Helmet } from 'react-helmet-async';
const staffData = [
    { name: "Mr. Bholu Singh Rathor", position: "Principal", description: "Visionary leader guiding the school towards academic excellence.", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTisFL1Bf4WgPgRbbhsCWWZD2tUfGAkHpqoQ&s" },
    { name: "Mr. Amresh Kumar", position: "Head Clerk", description: "Efficient administrator with years of experience in school management.", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTisFL1Bf4WgPgRbbhsCWWZD2tUfGAkHpqoQ&s" },
    
    { name: "Mr. Manoj Kumar", position: "Science Teacher", description: "Inspiring students to explore the wonders of the natural world.", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTisFL1Bf4WgPgRbbhsCWWZD2tUfGAkHpqoQ&s" },
    { name: "Mr. Manoj Kumar", position: "Hindi Teacher", description: "Dedicated to preserving and promoting Hindi language and literature.", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTisFL1Bf4WgPgRbbhsCWWZD2tUfGAkHpqoQ&s" },
    { name: "Mr. Manish Kumar", position: "S.ST. Teacher", description: "Passionate about bringing history and civics to life in the classroom.", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTisFL1Bf4WgPgRbbhsCWWZD2tUfGAkHpqoQ&s" },
    { name: "Mr. Monu Kumar", position: "Maths Teacher", description: "Makes complex mathematical concepts accessible and engaging.", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTisFL1Bf4WgPgRbbhsCWWZD2tUfGAkHpqoQ&s" },

    
    { name: "Mr. Rohit Kumar", position: "Accountant", description: "Ensuring financial transparency and efficiency in school operations.", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTisFL1Bf4WgPgRbbhsCWWZD2tUfGAkHpqoQ&s" },
    { name: "Mr. Shyam Kumar", position: "Science Teacher", description: "Encouraging scientific curiosity and critical thinking.", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTisFL1Bf4WgPgRbbhsCWWZD2tUfGAkHpqoQ&s" },
   
    { name: "Ms. Tanya Kumari", position: "Hindi Teacher", description: "Instilling pride in Hindi culture and literature.", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTisFL1Bf4WgPgRbbhsCWWZD2tUfGAkHpqoQ&s" },
];

const ProfileCard = ({ name, position, description, image }) => {
    return (
        <div className="profile-card">
            <div className="star-background">
                {[...Array(50)].map((_, i) => (
                    <div key={i} className="star"></div>
                ))}
            </div>
            <div className="header">
                <div className="avatar">
                    {/* <img src={image} alt={name} className="staff-image" /> */}
                </div>
            </div>
            <div className="content">
                <h2>{name}</h2>
                <p className="position">{position}</p>
                <center><div className="underline"></div></center>
                <p className="description">{description}</p>
            </div>
        </div>
    );
};

const TeachingStaff = () => {
    return (
        <div className="teaching-staff">
            <Helmet>
                <title>Meet Our Dedicated Teaching Staff - Prabhat Tara Vidya Niketan</title>
                <meta name="title" content="Meet Our Dedicated Teaching Staff - Prabhat Tara Vidya Niketan" />
                <meta name="description" content="Explore the profiles of our dedicated teaching staff at Prabhat Tara Vidya Niketan Goraul, committed to academic excellence and student development." />
                
                <meta name="keywords" content="Prabhat Tara Vidya Niketan, Teaching Staff, Principal, Teachers, School Administration, CBSE School, Goraul, Vaishali" />
                
                <link rel="canonical" href="https://prabhattaravidyaniketan.in/teaching-staff" />
                
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />
                
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://prabhattaravidyaniketan.in/teaching-staff" />
                <meta property="og:title" content="Meet Our Dedicated Teaching Staff - Prabhat Tara Vidya Niketan" />
                <meta property="og:description" content="Learn more about the exceptional teaching and administrative staff at Prabhat Tara Vidya Niketan, shaping the future of our students." />
                <meta property="og:image" content="https://prabhattaravidyaniketan.in/images/staff.jpg" />  {/* Replace with your staff image URL */}
                
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://prabhattaravidyaniketan.in/teaching-staff" />
                <meta property="twitter:title" content="Meet Our Dedicated Teaching Staff - Prabhat Tara Vidya Niketan" />
                <meta property="twitter:description" content="Introducing the staff members who contribute to academic excellence and a nurturing environment at Prabhat Tara Vidya Niketan." />
                <meta property="twitter:image" content="https://prabhattaravidyaniketan.in/images/staff.jpg" /> {/* Replace with your staff image URL */}
                
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="icon" href="/images/logo.jpg" /> {/* Replace with your favicon URL */}
            </Helmet>

            <h1>Our Teaching Staff</h1>
            <div className="staff-grid">
                {staffData.map((staff, index) => (
                    <ProfileCard
                        key={index}
                        name={staff.name}
                        position={staff.position}
                        description={staff.description}
                        image={staff.image}
                    />
                ))}
            </div>
        </div>
    );
};

export default TeachingStaff;